<template>
  <v-container
    fluid
    tag="section"
  >
    <!--  -->
    <v-row justify="center">
      <!--  -->
      <v-col
        cols="12"
        md="8"
      >
        <!--  -->
        <base-material-card>
          <!--  -->
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Edit Your Community
            </div>
          </template>
          <!--  -->
          <v-form>
            <!--  -->
            <v-container class="py-0">
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="community.data.id"
                    label="ID"
                    disabled
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    v-model="community.data.name"
                    dark
                    filled
                    label="Name"
                    clearable
                  >
                    <!--  -->
                  </v-text-field>
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-select
                    v-model="community.data.status"
                    :items="status_items"
                    label="Status"
                  >
                    <!--  -->
                  </v-select>
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="community.data.created_at | moment('D MMM YYYY')"
                    label="Di Buat"
                    disabled
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="community.data.updated_at | moment('D MMM YYYY')"
                    label="Di Perbaharui"
                    disabled
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    v-model="community.data.prefix"
                    label="prefix"
                  >
                    <!--  -->
                  </v-text-field>
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="community.data.transaction_count"
                    label="Jumlah Transaksi"
                    disabled
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="getUpdate"
                  >
                    SUNTING
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    data () {
      return {
        community: {
          meta: {},
          data: {
            name: '',
            status: '',
            prefix: '',
          },
        },
        isEditing: false,
        status_items: ['active', 'inactive'],
      }
    },
    mounted () {
      this.getCommunity()
    },
    methods: {
      getUpdate () {
        axios
          .patch('/v1/community/' + this.$route.params.id, {
            name: this.community.data.name,
            status: this.community.data.status,
            prefix: this.community.data.prefix,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/community' })
            }
            // update
            this.$toast.success('Berhasil Di Sunting', {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          })
      },
      setCommunity (data) {
        this.community = data
        console.log(this.community)
      },
      getCommunity () {
        axios
          .get('/v1/community/' + this.$route.params.id)
          .then(response => {
            this.setCommunity(response.data)
          })
          .catch(e => {
            console.error(e)
          })
      },
    },
  }
</script>

<style></style>
